import React, { FC, Fragment } from 'react'
import { X } from 'phosphor-react'
import { resolveURL } from '@/lib/routes'
import { ImageAsset } from '@/types/site.interface'
import { Popover, Transition } from '@headlessui/react'
import { SocialLinks } from '../elements/social-links'
import { SocialLink } from '../elements/social-links.interface'
import { SiteLogo } from '../molecules/site-logo'
import { MenuProps } from './menu.interface'
import { NavigationItem } from './navigation-item'

interface GenericHeaderMobileProps {
  menuMobilePrimary?: MenuProps
  menuMobileSecondary?: MenuProps
  socialLinks?: SocialLink[]
  siteLogo?: ImageAsset
  siteName?: string
}

export const GenericHeaderMobile: FC<GenericHeaderMobileProps> = ({
  menuMobilePrimary,
  menuMobileSecondary,
  socialLinks,
  siteLogo,
  siteName,
}) => {
  return (
    <Transition
      as={Fragment}
      enter="duration-150 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition md:hidden"
      >
        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="px-5 pt-5 pb-6 sm:pb-8">
            <div className="flex items-center justify-between">
              <SiteLogo siteLogo={siteLogo} siteName={siteName} />
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Close menu</span>
                  <X className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6 sm:mt-8">
              <nav>
                <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                  {menuMobilePrimary?.items.map((item, index) => (
                    <NavigationItem key={`k-${index}-${item.id}`} title={item.title} href={resolveURL(item)} />
                  ))}
                </div>
              </nav>
            </div>
          </div>
          {menuMobileSecondary && menuMobileSecondary.items.length && (
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                {menuMobileSecondary?.items.map((item, index) => (
                  <NavigationItem key={`k-${index}-${item.id}`} title={item.title} href={resolveURL(item)} />
                ))}
              </div>
            </div>
          )}
          {socialLinks && (
            <div className="py-6 px-5">
              <SocialLinks socialLinks={socialLinks} />
            </div>
          )}
        </div>
      </Popover.Panel>
    </Transition>
  )
}
