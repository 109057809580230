import { LinkInfo } from '@/data/queries.interface'
import { ImageAsset } from '@/types/site.interface'
import { imageBuilder } from './sanity'

// determine if current page is active or not
export const getActive = (isStatic, pageSlug, router) => {
  if (isStatic !== false) {
    return isStatic == router.pathname.replace(/^\//g, '')
  } else {
    const slugs = [].concat(router.query.slug)
    const currentPath = slugs ? slugs.join('/') : router.asPath.replace(/^\//g, '')
    return currentPath == pageSlug
  }
}

export const splitSlug = (slug: string) => JSON.stringify([slug, `/${slug}`, `/${slug}/`])

export const getStaticRoute = (name) => {
  switch (name) {
    default:
      return false
  }
}

export const getDynamicRoute = (name) => {
  switch (name) {
    default:
      return false
  }
}

export const resolveURL = ({ url, page, type }: LinkInfo) => {
  switch (type) {
    case 'link':
      return url

    case 'page':
      return page.isHome ? '/' : page.slug
    default:
      return null
  }
}

export const imageUrl = (image: ImageAsset, width?: number, height?: number, format?, quality?) => {
  if (!image) return null
  let imgSrc = imageBuilder.image(image)

  if (width) {
    imgSrc = imgSrc.width(Math.round(width))
  }

  if (height) {
    imgSrc = imgSrc.height(Math.round(height))
  }

  if (format) {
    imgSrc = imgSrc.format(format)
  }

  if (quality) {
    imgSrc = imgSrc.quality(quality)
  }

  return imgSrc.fit('max').auto('format').url()
}
