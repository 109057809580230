import { MenuItem } from './menu.interface'

/**
 * Converts a flat list of items and control-elements (headings and breaks) into a hierarchial structure
 * suited for rendering
 *
 * @param items
 * @returns
 */
export const prepareNavSections = (items: MenuItem[]) => {
  const columns = []
  let column = null
  let section = null

  const newColumm = () => {
    column = {
      sections: [],
    }
  }
  const newSection = (title = null) => {
    section = {
      title,
      items: [],
    }
  }

  const endColumn = () => {
    columns.push(column)
  }
  const endSection = () => {
    if (section) {
      column.sections.push(section)
    }
  }

  // create first column
  newColumm()

  items.forEach((item) => {
    switch (item._type) {
      // encounter section-header
      case 'navSection':
        // end and push a possible existing section
        endSection()
        // create a new section with given title
        newSection(item.title)
        break
      // encounter a column-break
      case 'navBreak':
        // end and push a possible existing section
        endSection()
        // end and push the current column
        endColumn()
        // create a new column
        newColumm()
        // empty section, but don't create one.
        // the next navSection or navItem will decide how the new section starts
        section = null
        break
      // encounter a navigation item
      case 'navItem':
        if (!section) {
          // create a new section if it not exists (i.e. first item or first after break)
          newSection()
        }

        // push item to current section
        section.items.push(item)
        break
      default:
        break
    }
  })
  // end and push a current section
  endSection()
  // end and push the current column
  endColumn()

  return columns
}
