import React, { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { UseNextSanityImageOptions } from 'next-sanity-image'
import { nextSanityImage } from '@/lib/sanity'
import { ImageAsset } from '@/types/site.interface'

interface SiteLogoInterface {
  siteLogo: ImageAsset
  siteName?: string
}

const nextSanityImageOptions: UseNextSanityImageOptions = {
  enableBlurUp: false,
}

export const SiteLogo: FC<SiteLogoInterface> = ({ siteLogo, siteName = 'Your Site' }) => {
  const router = useRouter()
  const { pathname } = router
  const Wrapper =
    pathname === '/'
      ? ({ children }) => (
          <button aria-label="Go Home" onClick={() => window.scrollTo(0, 0)}>
            {children}
          </button>
        )
      : ({ children }) => (
          <Link href="/" scroll={false} aria-label="Go Home">
            <>{children}</>
          </Link>
        )

  const img = nextSanityImage(siteLogo, nextSanityImageOptions)

  const aspectRatio = img.width / img.height

  const sizesString = `
    (min-width: 640px) ${Math.ceil(80 * aspectRatio)}px,
    (min-width: 768px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1024px) ${Math.ceil(90 * aspectRatio)}px,
    (min-width: 1280px) ${Math.ceil(100 * aspectRatio)}px,
    (min-width: 1536px) ${Math.ceil(200 * aspectRatio)}px,
    ${Math.ceil(50 * aspectRatio)}px
    `

  return (
    <Wrapper>
      <span className="sr-only">{siteName}</span>
      {img.src && <Image {...img} sizes={sizesString} alt={siteName} className="h-12 w-auto sm:h-20 object-contain" />}
    </Wrapper>
  )
}
