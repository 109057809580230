/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isString = (input: any): input is string => {
  return typeof input === 'string'
}
export const handleHref = (value: string) => {
  return value.startsWith('/') || value.startsWith('http') ? value : value.startsWith('#') ? value : `/${value}`
}

export const toCamelCase = (str: string) => {
  if (!isString(str) || str === '') return null
  return (str.slice(0, 1).toLowerCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1)
    })
}
