import { FC } from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import { jsonLdComponent, JsonLdProps } from './jsonld-component'

interface Props {
  seo: NextSeoProps
  ldJson: JsonLdProps
  type: string
}

const HeadSeo: FC<Props> = ({ seo = {}, ldJson, type }) => {
  const Component = jsonLdComponent(type)
  return (
    <>
      <NextSeo {...seo} />
      {ldJson && <Component {...ldJson} />}
    </>
  )
}

export default HeadSeo
