import React, { FC } from 'react'
import cx from 'clsx'
import { Image as PhosphorImage } from 'phosphor-react'
import { nextSanityImage } from '@/lib/sanity'
import { ImageAsset } from '@/types/site.interface'
import Image from '@ignition/library/components/atoms/image'

type ObjectPosition =
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'
  | 'object-center'
  | 'object-center-bottom'

interface ImageElement {
  alt?: string
  image: ImageAsset
  sizes?: string
  quality?: number
  responsive?: boolean
  className?: string
  useFallbackImage?: boolean
  objectPosition?: ObjectPosition
  priority?: boolean
  fill?: boolean
}

// define our aspect ratio
export const aspectRatio = (customRatio: string) => {
  if (customRatio) {
    switch (customRatio) {
      case '1:1':
        return 'aspect-square'
      case '2:1':
        return 'aspect-2/1'
      case '3:1':
        return 'aspect-3/1'
      case '5:7':
        return 'aspect-5/7'
      case '4:6':
        return 'aspect-4/6'
      case '16:9':
        return 'aspect-video'
      default:
        return 'aspect-auto'
    }
  }
}

const ImageElement: FC<ImageElement> = ({
  alt,
  image,
  sizes,
  responsive,
  quality,
  className,
  priority = false,
  objectPosition,
  useFallbackImage = false,
  fill,
}) => {
  if (!image || !image?.asset) {
    return useFallbackImage ? <PhosphorImage className="w-full h-full text-gray-300" weight="light" /> : null
  }

  const buildOptions = {
    enableBlurUp: true,
    blurUpImageQuality: quality || 40,
    blurUpImageWidth: 150,
    blurUpAmount: 80,
  }

  const { width, height, ...sanityProps } = nextSanityImage(image, buildOptions)
  return (
    <picture className={cx(aspectRatio(image.customRatio), className)} style={{ aspectRatio: image?.aspectRatio }}>
      <Image
        {...sanityProps}
        sizes={sizes}
        fill={fill}
        {...(fill ? {} : { width, height })}
        alt={alt || image.alt || image.asset?.alt || ''}
        className={cx(objectPosition, responsive && 'object-contain', fill && 'object-cover')}
        priority={priority}
        title={image.title}
        copyright={image.copyright}
      />
    </picture>
  )
}

export default ImageElement
