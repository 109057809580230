import React, { FC } from 'react'
import {
  AppleLogo,
  FacebookLogo,
  GithubLogo,
  Globe,
  InstagramLogo,
  LinkedinLogo,
  PinterestLogo,
  SpotifyLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'phosphor-react'
import ImageElement from './image'
import { SocialLink, SocialLinkType } from './social-links.interface'

interface SocialLinksInterface {
  socialLinks?: SocialLink[]
}

interface SocialIconInterface {
  icon: SocialLinkType
}

const Icon: FC<SocialIconInterface> = ({ icon }) => {
  switch (icon) {
    case 'facebook':
      return <FacebookLogo weight="fill" />
    case 'instagram':
      return <InstagramLogo weight="regular" />
    case 'linkedin':
      return <LinkedinLogo weight="fill" />
    case 'twitter':
      return <TwitterLogo weight="fill" />
    case 'apple':
      return <AppleLogo weight="fill" />
    case 'spotify':
      return <SpotifyLogo weight="fill" />
    case 'youtube':
      return <YoutubeLogo weight="fill" />
    case 'github':
      return <GithubLogo weight="fill" />
    case 'pinterest':
      return <PinterestLogo weight="fill" />
    case 'website':
      return <Globe weight="regular" />

    default:
      return <></>
  }
}

const styles = {
  customIcon: ' w-6 h-7 block',
}

export const SocialLinks: FC<SocialLinksInterface> = ({ socialLinks }) => {
  const renderIcon = ({ _key, icon, url }: SocialLink) => (
    <a
      key={_key}
      href={url}
      target="_blank"
      rel="noreferrer"
      className="text-header-social hover:text-header-social-hover text-2xl"
      title={icon}
    >
      <span className="sr-only">{icon}</span>
      <Icon icon={icon} />
    </a>
  )

  const renderCustomIcon = ({ _key, customIcon, iconName }: SocialLink) => {
    return (
      <a key={_key}>
        <ImageElement image={customIcon} className={styles.customIcon} responsive alt={iconName} />
      </a>
    )
  }

  return (
    <div className="flex space-x-6">
      {socialLinks.map(({ _key, icon, customIcon, iconName, url }: SocialLink) =>
        icon !== 'custom'
          ? renderIcon({ _key, icon, url })
          : renderCustomIcon({ _key, customIcon, icon, iconName, url }),
      )}
    </div>
  )
}
