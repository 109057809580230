import React, { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
// import Footer from '@/components/footer'
import GenericHeader from '@/components/navigation/generic-header'
import { PageProps, PageTypeProps, SiteProps } from '@/types/site.interface'
// import Footer from '@/components/footer'
import HeadSeo from '@ignition/library/src/lib/seo/head-seo'
import { GenericFooter } from './navigation/generic-footer'

const PromoBar = dynamic(() => import('./promo-bar'))
const GtmScript = dynamic(() => import('@ignition/library/components/atoms/tracker/gtm-script'))

interface Props extends PageTypeProps {
  children: ReactNode
  site: SiteProps
  page: PageProps
}

const Layout: FC<Props> = ({ page, site = {}, seo = {}, ldJson, children }) => {
  const { promo } = site.header
  const { socialLinks, siteLogo, gtm } = site
  return (
    <>
      <HeadSeo seo={seo} ldJson={ldJson} type={page?.entryType || page?.type} />
      {gtm?.isEnable && <GtmScript id={gtm?.id} />}
      <div>
        {/* <Header data={site.header} /> */}
        {promo?.enabled && <PromoBar text={promo.text} link={promo.link} />}
        <GenericHeader
          menuDesktopPrimary={site.header.menuDesktopPrimary}
          menuDesktopSecondary={site.header.menuDesktopSecondary}
          menuMobilePrimary={site.header.menuMobilePrimary}
          menuMobileSecondary={site.header.menuMobileSecondary}
          socialLinks={socialLinks}
          siteLogo={siteLogo}
          siteName={site.title}
        />
        <main id="content">{children}</main>
        <GenericFooter
          copyright={site.footer?.copyright}
          slogan={site.footer?.slogan}
          menuFooter={site.footer?.menuFooter}
          socialLinks={socialLinks}
          siteLogo={siteLogo}
          siteName={site.title}
        />
      </div>
    </>
  )
}

export default Layout
