import { useNextSanityImage, UseNextSanityImageOptions } from 'next-sanity-image'
import { Preview } from '@/types/site.interface'
import createSanityClient from '@sanity/client'
import sanityImage from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

const options = {
  dataset: process.env.SANITY_PROJECT_DATASET,
  projectId: process.env.SANITY_PROJECT_ID,
  useCdn: true,
  apiVersion: '2021-10-21',
}

const optionsWithCredentials = {
  withCredentials: true,
  token: process.env.SANITY_API_TOKEN,
}

export const sanityClient = createSanityClient({ ...options, ...optionsWithCredentials })
export const imageBuilder = sanityImage(sanityClient)

export function createPreviewClient(token: string) {
  return createSanityClient({
    ...options,
    useCdn: false,
    token,
  })
}

export function getSanityClient(preview?: Preview) {
  if (preview?.active) {
    return createPreviewClient(preview?.token)
  } else {
    return sanityClient
  }
}

/*eslint-disable react-hooks/rules-of-hooks*/
export const nextSanityImage = (asset: SanityImageSource, options: UseNextSanityImageOptions) => {
  return { ...useNextSanityImage(sanityClient, asset, options) }
}

// Sanity client form submission
const optionsWriterCredentials = {
  withCredentials: true,
  token: process.env.SANITY_SUBMISSION_API_TOKEN,
}

export const submissionSanityClient = createSanityClient({ ...options, ...optionsWriterCredentials })
