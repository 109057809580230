import { FC, useState } from 'react'
import clsx from 'clsx'
import NextImage, { ImageProps as NextImageProps } from 'next/image'

export interface ImageProps extends NextImageProps {
  category?: string
  title?: string
  copyright?: string
}

const Image: FC<ImageProps> = ({ className, priority, loading, onLoadingComplete, blurDataURL, ...props }) => {
  const [isReady, setReady] = useState(false)
  const lazy = loading !== 'eager' && !priority

  const handleOnLoad = (result: HTMLImageElement) => {
    setReady(true)
    if (typeof onLoadingComplete === 'function') {
      onLoadingComplete(result)
    }
  }

  return (
    <NextImage
      {...props}
      priority={priority}
      loading={loading}
      onLoadingComplete={handleOnLoad}
      className={clsx(className, 'transition-opacity duration-300', lazy && !isReady ? 'opacity-0' : 'opacity-100')}
      blurDataURL={blurDataURL}
      placeholder={blurDataURL ? 'blur' : 'empty'}
    />
  )
}

export default Image
