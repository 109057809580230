import dynamic from 'next/dynamic'
import {
  ArticleJsonLdProps,
  JobPostingJsonLdProps,
  SiteLinksSearchBoxJsonLdProps,
  SocialProfileJsonLdProps,
  WebPageJsonLdProps,
} from 'next-seo'

export type JsonLdProps =
  | ArticleJsonLdProps
  | JobPostingJsonLdProps
  | SocialProfileJsonLdProps
  | SiteLinksSearchBoxJsonLdProps
  | WebPageJsonLdProps

const ArticleJsonLd = dynamic(() => import('next-seo').then((c) => c.ArticleJsonLd))
const WebPageJsonLd = dynamic(() => import('next-seo').then((c) => c.WebPageJsonLd))
const JobPostingJsonLd = dynamic(() => import('next-seo').then((c) => c.JobPostingJsonLd))
const SiteLinksSearchBoxJsonLd = dynamic(() => import('next-seo').then((c) => c.SiteLinksSearchBoxJsonLd))
const SocialProfileJsonLd = dynamic(() => import('next-seo').then((c) => c.SocialProfileJsonLd))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonLdComponent = (type: string): any => {
  switch (type) {
    case 'page':
    case 'article':
    case 'gallery':
      return ArticleJsonLd
    case 'job':
      return JobPostingJsonLd
    case 'author':
      return SocialProfileJsonLd
    case 'search':
      return SiteLinksSearchBoxJsonLd
    default:
      return WebPageJsonLd
  }
}
